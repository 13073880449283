// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".menu-button_button_19gw0 {\n  background: none;\n  border: none;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n      -ms-flex-align: center;\n          align-items: center;\n  font-family: 'Titillium Web';\n  font-weight: 600;\n  font-size: 1.2rem;\n  text-decoration: none;\n  margin-right: 1rem;\n  cursor: pointer;\n}\n\n.menu-button_button_19gw0:last-of-type {\n  margin-right: 0;\n}\n\n.menu-button_icon_kp1EO {\n  height: 3.5rem;\n  margin-top: 0.25rem;\n  margin-right: -1rem;\n\n  z-index: 1;\n}\n\n.menu-button_content_2vXFP {\n  color: black;\n  background-color: white;\n  padding: 0.25rem 1rem 0.25rem 1.5rem;\n  -webkit-box-shadow: 0.125rem 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.5);\n          box-shadow: 0.125rem 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.5);\n}\n\n.menu-button_button-vertical_2h4s- {\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n      -ms-flex-align: center;\n          align-items: center;\n}\n\n.menu-button_button-vertical_2h4s- > .menu-button_icon_kp1EO {\n  height: 3rem;\n  margin: 0 0 -0.3rem 0;\n}\n\n.menu-button_button-vertical_2h4s-:first-child > .menu-button_icon_kp1EO {\n  margin: 0.3rem 0 -0.6rem 0;\n}\n\n.menu-button_button-vertical_2h4s- > .menu-button_content_2vXFP {\n  font-size: 1rem;\n  padding: 0 0.2rem;\n  -webkit-box-shadow: 0.125rem 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.5);\n          box-shadow: 0.125rem 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.5);\n}\n\n@media (max-width: 1024px) {\n  .menu-button_button-vertical_2h4s- {\n    width: 80%;\n    height: auto;\n  }\n  .menu-button_button-vertical_2h4s- > .menu-button_icon_kp1EO {\n    width: 2.5rem;\n    height: 2.5rem;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"button": "menu-button_button_19gw0",
	"icon": "menu-button_icon_kp1EO",
	"content": "menu-button_content_2vXFP",
	"buttonVertical": "menu-button_button-vertical_2h4s-"
};
module.exports = exports;
