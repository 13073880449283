import aL00 from '../shared_assets/L00.jpg'

const url =
  'https://wdradaptiv-vh.akamaihd.net/i/medp/ondemand/weltweit/fsk0/213/2136903/,2136903_26555464,2136903_26555459,2136903_26555461,2136903_26555460,2136903_26555463,2136903_26555462,.mp4.csmil/master.m3u8'

export default {
  id: '06',
  name: 'Hallo Welt (1/10)',
  subtitle: 'Starte mit den ersten Blöcken',
  image: aL00,
  video: url,
}
