// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "html,\nbody,\n.index_app_i2M-d {\n  /* probably unecessary, transitional until layout is refactored */\n  width: 100%;\n  height: 100%;\n  margin: 0;\n\n  /* Setting min height/width makes the UI scroll below those sizes */\n  min-width: 1024px;\n  min-height: 640px; /* Min height to fit sprite/backdrop button */\n}\n\n.index_mobile-overlay-root_1jatu {\n  display: none;\n  max-width: 100%;\n  height: 100%;\n}\n\n@media only screen and (max-width: 600px) {\n  .index_mobile-overlay-root_1jatu {\n    display: block;\n  }\n\n  .index_app_i2M-d {\n    display: none;\n  }\n\n  html,\n  body {\n    min-width: 0;\n    min-height: 0;\n  }\n}\n\n/* @todo: move globally? Safe / side FX, for blocks particularly? */\n\n* {\n  -webkit-box-sizing: border-box;\n          box-sizing: border-box;\n}\n", ""]);
// Exports
exports.locals = {
	"app": "index_app_i2M-d",
	"mobileOverlayRoot": "index_mobile-overlay-root_1jatu"
};
module.exports = exports;
