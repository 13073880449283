// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".loader_loader_2ZO_g {\n  -webkit-animation: loader_rotation_3iwuO 2s infinite linear;\n          animation: loader_rotation_3iwuO 2s infinite linear;\n}\n\n@-webkit-keyframes loader_rotation_3iwuO {\n  from {\n    -webkit-transform: rotate(0deg);\n  }\n  to {\n    -webkit-transform: rotate(359deg);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"loader": "loader_loader_2ZO_g",
	"rotation": "loader_rotation_3iwuO"
};
module.exports = exports;
