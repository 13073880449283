import aL04 from '../game-04-wimmelbild/assets/L04.png'

const url =
  'https://wdradaptiv-vh.akamaihd.net/i/medp/ondemand/weltweit/fsk0/213/2136932/,2136932_26555830,2136932_26555825,2136932_26555827,2136932_26555826,2136932_26555829,2136932_26555828,.mp4.csmil/master.m3u8'

export default {
  id: '10',
  name: 'Wimmelbild (5/10)',
  subtitle: 'Lasse Maus, Elefant und Ente per Klick starten',
  image: aL04,
  video: url,
}
