const url =
  'https://wdradaptiv-vh.akamaihd.net/i/medp/ondemand/weltweit/fsk0/215/2157521/,2157521_26882822,2157521_26882817,2157521_26882819,2157521_26882818,2157521_26882821,2157521_26882820,.mp4.csmil/master.m3u8'

export default {
  id: '14',
  name: 'Deine Story (9/10)',
  subtitle: 'Erfinde eine Geschichte und spiele selbst mit',
  image: null,
  video: url,
}
