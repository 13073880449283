
import React from 'react';


export const attributes = {"title":"Impressum"};
export default function() {
  return (
    <div>
      <p><strong>Copyright</strong></p>
<p>© WDR {(new Date().getFullYear())} - Alle Figuren und Sounds aus der Sendung mit der Maus sind urheberrechtlich geschützt. <a href="https://www.wdrmaus.de/copyright.php5">https://www.wdrmaus.de/copyright.php5</a></p>
<p>Die Anwendung basiert auf &quot;Scratch&quot;, einem Projekt des MIT Media Lab.
Es gelten diese Lizenz-Bedingungen:
https://en.scratch-wiki.info/wiki/Scratch_Source_Code_License
https://github.com/LLK/scratch-flash/blob/master/COPYING</p>
<p>Der vom WDR-modifizierte Programmcode steht unter
https://github.com/wdr-data/code4maus Open Source zur Verfügung.</p>
<p><strong>Anbieter:</strong></p>
<p>Westdeutscher Rundfunk Köln</p>
<p>Anstalt des öffentlichen Rechts</p>
<p>Appellhofplatz 1</p>
<p>50667 Köln</p>
<p>Postanschrift: 50600 Köln</p>
<p>Tel.: 0221/220 - 0</p>
<p>Fax: 0221/220 - 4800</p>
<p>E-Mail: redaktion@wdr.de</p>
<p>Umsatzsteuer-Identifikationsnummer: DE 122 79 0169</p>
<p><strong>Vertretungsberechtigte sowie Verantwortliche:</strong></p>
<p>Intendant Tom Buhrow</p>
<p>Westdeutscher Rundfunk Köln</p>
<p>Postanschrift: 50600 Köln</p>
<p><strong>Weitere Verantwortliche:</strong></p>
<p>Die programmbegleitende Online-Präsenz von &quot;Sendung mit der Maus&quot; wird von der Maus-Redaktion selbst erstellt. Verantwortlich ist:</p>
<p>Matthias Körnich</p>
<p>Postanschrift:</p>
<p>Westdeutscher Rundfunk Köln</p>
<p>Redaktion &quot;Sendung mit der Maus&quot;</p>
<p>50614 Köln</p>
<p>E-Mail: maus@wdr.de</p>
<p>Das WDR-Online Angebot wird gestaltet vom Programmbereich Internet. Dieser ist für den aktuellen themenbezogenen Teil des Angebots zuständig.</p>
<p>Leiter: Stefan Moll</p>
<p>Postanschrift: Westdeutscher Rundfunk Köln</p>
<p>50600 Köln</p>
<p>E-Mail: redaktion@wdr.de</p>
<p>Die Unternehmensdarstellung wird von der Abteilung Marketing verantwortet.</p>
<p>Leitung: Magnus Schweers</p>
<p>Postanschrift: Westdeutscher Rundfunk</p>
<p>50600 Köln</p>
<p><strong><a href="http://www.wdr.de/themen/global/urheber/index.jhtml">Hinweise zum Urheberrecht</a></strong></p>

    </div>
  );
};