import a000 from '../example-03-zahlen-raten/assets/000.png'

const url =
  'https://wdradaptiv-vh.akamaihd.net/i/medp/ondemand/weltweit/fsk0/215/2157517/,2157517_26882751,2157517_26882746,2157517_26882748,2157517_26882747,2157517_26882750,2157517_26882749,.mp4.csmil/master.m3u8'

export default {
  id: '12',
  name: 'Zahlen-Raten (7/10)',
  subtitle: 'Schreibe dein erstes Wenn-Dann-Spiel',
  image: a000,
  video: url,
}
