import title from '../video_assets/Elefant-rockt.png'

const url =
  'https://wdradaptiv-vh.akamaihd.net/i/medp/ondemand/weltweit/fsk0/245/2459026/,2459026_35926183,2459026_35926184,2459026_35926185,2459026_35926181,2459026_35926186,2459026_35926182,.mp4.csmil/master.m3u8'

export default {
  id: '01',
  name: 'Dein erster Beat (Musik 1/5)',
  subtitle: 'Programmiere mit Blöcken einen Beat',
  image: title,
  video: url,
}
