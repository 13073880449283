import aL05 from '../game-08-kugelfisch/assets/L05.png'

const url =
  'https://wdradaptiv-vh.akamaihd.net/i/medp/ondemand/weltweit/fsk0/215/2157522/,2157522_26882831,2157522_26882826,2157522_26882828,2157522_26882827,2157522_26882830,2157522_26882829,.mp4.csmil/master.m3u8'

export default {
  id: '15',
  name: 'Kugelfisch (10/10)',
  subtitle: 'Programmiere dein erstes Computerspiel',
  image: aL05,
  video: url,
}
