// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".inline-svg_wrapper_1BHVr {\n  display: inline-block;\n}\n\n.inline-svg_wrapper_1BHVr svg {\n  height: 100%;\n  width: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"wrapper": "inline-svg_wrapper_1BHVr"
};
module.exports = exports;
