// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".video-player_video_10XfA {\n  width: 100%;\n  height: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"video": "video-player_video_10XfA"
};
module.exports = exports;
