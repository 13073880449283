import aL007 from '../game-07-kunst-mit-der-maus/assets/L007.jpg'

const url =
  'https://wdradaptiv-vh.akamaihd.net/i/medp/ondemand/weltweit/fsk0/215/2157516/,2157516_26882717,2157516_26882712,2157516_26882714,2157516_26882713,2157516_26882716,2157516_26882715,.mp4.csmil/master.m3u8'

export default {
  id: '11',
  name: 'Maus-Art (6/10)',
  subtitle: 'Programmiere Kunst mit der Maus',
  image: aL007,
  video: url,
}
