// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/* #E5F0FF */ /* #E9F1FC */ /* #D9E3F2 */ /* 90% transparent version of motion-primary */ /* #FFFFFF */ /* 25% transparent version of ui-white */ /* 15% transparent version of black */ /* #575E75 */ /* #4C97FF */ /* #3373CC */ /* 35% transparent version of motion-primary */ /* #FF661A */ /* #E64D00 */ /* #CF63CF */ /* #BD42BD */ /* #FFAB19 */ /* #FF8C1A */ /* #0FBD8C */ /* Maus UI */ /* #E85E38 */ /* #5B84BF */ /* #9EBAE2 */ .menu-listing_project-wrapper_1wLrS {\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n  -webkit-flex-direction: column;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-justify-content: space-around;\n      -ms-flex-pack: distribute;\n          justify-content: space-around;\n  margin-right: 1rem;\n  margin-top: auto;\n  margin-bottom: auto;\n  padding: 1rem;\n  border: 1px solid rgb(216, 215, 215);\n  border-radius: 1rem;\n  -webkit-box-shadow: 2px 2px 12px -4px rgba(0, 0, 0, 0.7);\n          box-shadow: 2px 2px 12px -4px rgba(0, 0, 0, 0.7);\n  min-height: 17rem;\n  min-width: 16rem;\n  max-width: 16rem;\n  max-height: 17rem;\n  text-align: center;\n  color: black;\n  cursor: pointer;\n  text-decoration: none;\n  line-height: 1.2;\n} .menu-listing_project-wrapper_1wLrS:last-of-type {\n  margin-right: 0;\n} .menu-listing_project-wrapper_1wLrS img {\n  height: 7rem;\n  margin: 1rem 0;\n} .menu-listing_title_1Rz-q {\n  font-size: 1.25rem;\n} .menu-listing_video-content_160bE {\n  width: 70vw;\n  display: -webkit-box;\n  display: -webkit-flex;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n  -webkit-align-items: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n  -webkit-justify-content: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n}\n", ""]);
// Exports
exports.locals = {
	"projectWrapper": "menu-listing_project-wrapper_1wLrS",
	"title": "menu-listing_title_1Rz-q",
	"videoContent": "menu-listing_video-content_160bE"
};
module.exports = exports;
