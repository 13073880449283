import aL02 from '../game-02-maus-blinzelt/assets/L02.png'

const url =
  'https://wdradaptiv-vh.akamaihd.net/i/medp/ondemand/weltweit/fsk0/213/2136928/,2136928_26555787,2136928_26555782,2136928_26555784,2136928_26555783,2136928_26555786,2136928_26555785,.mp4.csmil/master.m3u8'

export default {
  id: '08',
  name: 'Die Maus blinzelt (3/10)',
  subtitle: 'Der erste Schritt zum Maus-Spot',
  image: aL02,
  video: url,
}
