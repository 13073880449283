import aL03 from '../game-03-maus-tanzt/assets/L03.png'

const url =
  'https://wdradaptiv-vh.akamaihd.net/i/medp/ondemand/weltweit/fsk0/213/2136930/,2136930_26555796,2136930_26555791,2136930_26555793,2136930_26555792,2136930_26555795,2136930_26555794,.mp4.csmil/master.m3u8'

export default {
  id: '09',
  name: 'Tanz-Maus (4/10)',
  subtitle: 'Bringe die Maus zum Tanzen',
  image: aL03,
  video: url,
}
